.Homepage {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-image: url("https://s3.ca-central-1.amazonaws.com/whenismynextholiday.com/Homepage/homepage-background.jpg");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
}

.Homepage__selection {
    background: transparent;
    height: auto;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Homepage__selection > p:first-child {
    letter-spacing: 10px;
    word-spacing: 99999px;
    line-height: 100px;
    font-weight: 300;
    font-size: 50px;
}

.Select {
    width: 230px !important;
    margin: 5% !important;
}

.react-dropdown-select-dropdown {
    height: 170px !important;
}

@media (max-height: 800px) {    
    .Homepage {
        overflow-y: hidden;
        padding-bottom: 10%;
    }  
}

@media (max-height: 600px) {    
    .Homepage {
        height: 600px;
    }  
}

@media (max-width: 1000px) {
    .Homepage__selection > p:first-child { 
        font-size: 40px;
        line-height: 80px;
    }
}

@media (max-width: 770px) {
    .Select {
        width: 200px !important;
        font-size: 14px;
    }

    .Homepage__selection > p:first-child { 
        letter-spacing: 8px;
        line-height: 70px;  
    }
}

@media (max-width: 600px) {
    .Homepage__selection > p:first-child { 
        font-size: 40px;
        line-height: 70px;  
    }
}

@media (max-width: 450px) {
    .Homepage__selection > p:first-child { 
        letter-spacing: 6px;
        font-size: 35px;
        line-height: 70px;  
    }
    
    .Select {
        width: 170px !important;
        font-size: 13px;
    }
}