.Maps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    padding: 2%;
    min-height: 100vh;
}

.Maps__title {
    color: white;
    letter-spacing: 20px;
    font-weight: 300;
    font-size: 45px;  
}

.Maps__title:after {
    display: block;
    content: '';
    border-bottom: solid 2px rgb(255, 255, 255);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
}

.Maps__scroll {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0px;
    z-index: 5;
    transition: 0.2s;
    animation: scroll 1s infinite;
}

.Maps__scroll:hover {
    transition: 0.2s;
    transform: translateY(5px);
    color: inherit;
    opacity: 0.5;
}

.Maps > p:nth-of-type(2) {
    margin-top: 8% !important;
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.Maps:hover > .Maps__title:after {
    transform: scaleX(1); transform-origin: 0% 50%;
}

.Maps > * {
    margin: 2% 0;
}

.us-state-map {
    width: 80%;
    height: auto;
}

@media (max-width: 1000px) {
    .Maps__title {
        font-size: 35px;   
    }

    .Maps__title:after {
        border-bottom: solid 1px rgb(255, 255, 255);
    }
}

@media (max-width: 770px) {
    .Maps {
        padding: 6% 2%;
        min-height: auto;
    }

    .Maps__title {
        letter-spacing: 15px;
        font-size: 30px;   
    }

    .Maps > p:nth-of-type(2) {
        font-size: 16px;
    }
}

@media (max-width: 600px) {
    .Maps {
        padding: 9% 2%;
    }

    .Maps__title {
        font-size: 30px;   
    }
}

@media (max-width: 450px) {
    .Maps {
        padding: 12% 2%;
    }

    .Maps__title {
        letter-spacing: 10px;
        font-size: 25px;   
    }

    .Maps > p:nth-of-type(2) {
        font-size: 13px;
    }
}