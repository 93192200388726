
a {
    transition: 0.3s;
}

a:hover {
    transition: 0.3s;
    opacity: 0.7;
    color: black;
}

.opaque {
    width: 101% !important;
    overflow-x: hidden !important;
}

.rodal-dialog {
    background: #000000; 
    background: -webkit-linear-gradient(to right, #434343, #000000);  
    background: linear-gradient(to right, #434343, #000000); 
    width: 100% !important;
    padding: 0 !important;
}

.bold {
    font-weight: 400;
}

.Footer {
    background: #000000; 
    background: -webkit-linear-gradient(to bottom, #434343, #000000);  
    background: linear-gradient(to bottom, #434343, #000000); 
    color: white;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3% 0%;
    width: 100%;
    height: auto;
}

.Footer a {
    color: white;
}

.Footer a:hover {
    cursor: pointer;
}

.Footer__Rodal {
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Footer__Rodal a {
    color: white;
}

@media (max-width: 1600px) {
    .opaque {
        width: 100% !important;
    }
}

@media (max-width: 1200px) {
    .Footer {
        padding: 5% 0;
        font-size: 14px;
    }
}

@media (max-width: 1000px) {
    .Footer {
        padding: 6% 0;
        font-size: 14px;
    }
}

@media (max-width: 770px) {
    .Footer {
        padding: 8% 0;
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .Footer {
        font-size: 13px;
        padding: 12% 0;
    }
}