.Timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Timer > p:nth-of-type(1) {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

.countdown {
    font-size: 100px;
    color:rgb(104, 119, 255);
}

@media (max-width: 1000px) {
    .Timer > p:nth-of-type(1) {
        font-size: 11px;
    }

    .countdown {
        font-size: 90px;
    }
}

@media (max-width: 770px) {
    .countdown {
        font-size: 70px;
    }
}

@media (max-width: 600px) {
    .Timer > p:nth-of-type(1) {
        font-size: 10px;
    }

    .countdown {
        font-size: 60px;
    }
}

@media (max-width: 450px) {
    .Timer > p:nth-of-type(1) {
        font-size: 9px;
    }

    .countdown {
        font-size: 50px;
    }
}