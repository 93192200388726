.Modal {
    height: 100% !important;
}

.Modal::-webkit-scrollbar {
    display: none !important;
}

.Modal__results {
    background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6); 
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
    height: auto;
    font-weight: 300;
    font-size: 20px;
}

.Modal__results__next {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 2% 0;
}

.Modal__results__next > p:nth-of-type(1) {
    font-size: 50px;
    font-style: italic;
}

.Modal__results__next > p:nth-of-type(2) {
    font-size: 30px;
    font-weight: 400;
}

.Modal__results__next > p:nth-of-type(3) {
    font-size: 18px;
}

.Modal__results__upcoming {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 2%;
}

.Modal__results__upcoming__container {
    flex-basis: 25%;
    margin: 3% 0;
}

.Modal__results__upcoming__container > p:first-child {
    font-weight: 400;
}

.Modal__results__upcoming__container > p:nth-of-type(2) {
    font-style: italic;
}

.Modal__results__contact {
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 4%;
}

a {
    text-decoration: none;
}

.rodal-dialog {
    background: #000000;
    height: auto !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    width: 100% !important;
    padding: 0 !important;
    z-index: 0 !important;
}

.rodal-dialog::-webkit-scrollbar {
    width: 0.3em;
    background-color: #cccccc;
}

.rodal-dialog::-webkit-scrollbar-thumb {
    background-color: rgb(133, 133, 133);
}

.button {
    margin-bottom: 1% !important;
}

.chip {
    margin: 4% 0 !important;
}

.backdrop {
    height: 100%;
    width: 100%;
    z-index: 11 !important;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    color: white;
    font-size: 14px;
}

@media (max-width: 1000px) {
    .Modal__results__next {
        font-size: 16px;
    }

    .Modal__results__next > p:nth-of-type(1) {
        font-size: 40px;
    }
    
    .Modal__results__next > p:nth-of-type(2) {
        font-size: 24px;
    }
    
    .Modal__results__next > p:nth-of-type(3) {
        font-size: 16px;
    }
    
    .Modal__results__upcoming {
        font-size: 14px;
    }

    .Modal__results__upcoming__container {
        flex-basis: 33%;
    }

    .button {
        margin: 5% 0 2% 0 !important;
    }
}

@media (max-width: 770px) {
    .Modal__results {
        font-size: 20px;
    }

    .Modal__results__next {
        padding: 5% 0;
        font-size: 14px;
    }

    .Modal__results__next > p:nth-of-type(1) {
        font-size: 30px;
    }
    
    .Modal__results__next > p:nth-of-type(2) {
        font-size: 20px;
    }
    
    .Modal__results__next > p:nth-of-type(3) {
        font-size: 14px;
    }

    .Modal__results__upcoming {
        font-size: 14px;
    }

    .Modal__results__upcoming__container {
        flex-basis: 45%;
    }

    .button {
        margin: 8% 0 3% 0 !important;
    }
}

@media (max-width: 600px) {
    .Modal__choose {
        padding: 7% 0;
    }

    .Modal__results__next {
        padding: 8% 10%;
        font-size: 13px;
    }

    .Modal__results__next > p:nth-of-type(1) {
        font-size: 25px;
    }
    
    .Modal__results__next > p:nth-of-type(2) {
        font-size: 16px;
    }
    
    .Modal__results__next > p:nth-of-type(3) {
        font-size: 13px;
    }

    .Modal__results__upcoming {
        font-size: 13px;
    }

    .Modal__results__upcoming__container {
        flex-basis: 100%;
    }

    .button {
        font-size: 13px !important;
        margin: 10% 0 3% 0 !important;
    }
}

@media (max-width: 450px) {
    .Modal__choose {
        padding: 14% 0;
    }

    .Modal__results__next {
        padding: 12%;
        font-size: 12px;
    }

    .Modal__results__next > p:nth-of-type(1) {
        font-size: 20px;
    }
    
    .Modal__results__next > p:nth-of-type(2) {
        font-size: 14px;
    }
    
    .Modal__results__next > p:nth-of-type(3) {
        font-size: 12px;
    }

    .Modal__results__upcoming {
        font-size: 13px;
    }

    .Modal__results__upcoming__container {
        margin: 6% 0;
    }

    .button {
        font-size: 12px !important;
        margin: 14% 0 4% 0 !important;
    }
}

@keyframes scroll {
    0% {opacity: 1}
    50% {opacity: 1}
    100% {opacity: 0}
}